import { AnnouncementModal } from '@air/zephyr';
import Router from 'next/router';

import { Routes } from '~/constants/routes';
import { INVALID_WORKSPACE_MODAL } from '~/constants/testIDs';

export const InvalidWorkspace = ({ onClose }: AirModalProps) => {
  return (
    <AnnouncementModal
      data-testid={INVALID_WORKSPACE_MODAL}
      imageSource="https://app.air.inc/_next/static/images/no-results-91aee41cd33fe4e3fe8b9a35d813da98.png"
      onDismiss={() => {
        Router.replace(Routes.home);
        onClose();
      }}
      modalLabel="Uh oh!"
      modalDescription="Could not find requested Workspace ID. Please confirm this user has access to the workspace you’re attempting to navigate to! If you’re certain this user has access to the workspace and there hasn’t been a typo, send a message to #frontend in Slack."
      cta="Go Home"
      withCloseButton={false}
    />
  );
};
