import { useAirModal } from '@air/provider-modal';
import { useSysadmin } from '@air/sysadmin';
import { isString } from 'lodash';
import Router, { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getLayout } from '~/components/Layouts/HomeLayout/HomeLayout';
import { OnboardingModal } from '~/components/Modals/OnboadingQuestionsModal/OnboardingQuestionsModal';
import Spinner from '~/components/UI/Spinner';
import { Routes } from '~/constants/routes';
import { useLocationDidChange } from '~/hooks/router/useLocationDidChange';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useWorkspaceQueryParam } from '~/hooks/useWorkspaceQueryParam';
import { useWorkspaceRedirectState } from '~/hooks/useWorkspaceRedirectState';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { workspaceIdQueryParamSelector } from '~/store/router/selectors';
import { useGetDefaultBoard } from '~/swr-hooks/boards/useGetDefaultBoard';
import { useWorkspaces } from '~/swr-hooks/workspaces/useWorkspaces';
import { canChangeWorkspaceDetails } from '~/utils/permissions/workspacePermissions';

const Home = () => {
  const { goToBoardPage } = useGoToBoardPage();
  const { data: workspaces } = useWorkspaces();
  const { workspaceRedirectState } = useWorkspaceRedirectState();
  const { data: board, error: boardError } = useGetDefaultBoard();
  const { locationDidChange } = useLocationDidChange('/home');
  const wsidParam = useSelector(workspaceIdQueryParamSelector);
  const [showOnboardingModal] = useAirModal(OnboardingModal);
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const { query } = useRouter();
  const { isInternal } = useSysadmin();
  const shouldShowOnboardingModal =
    isString(query.showOnboarding) && query.showOnboarding === 'true' && canChangeWorkspaceDetails(permissions);

  useWorkspaceQueryParam();

  useEffect(() => {
    if (isInternal) {
     Router.push(Routes.media.all)
    }
  }, [isInternal]);
  
  useEffect(() => {
    if (boardError) {
      Router.replace(Routes.media.all);
    }
  }, [boardError]);

  useEffect(() => {
    if (
      board &&
      !!workspaces?.length &&
      workspaceRedirectState === 'hasAccessToWorkspace' &&
      !wsidParam &&
      !locationDidChange()
    ) {
      goToBoardPage({ board, method: 'replace' });
    }
  }, [goToBoardPage, workspaceRedirectState, board, wsidParam, locationDidChange, workspaces?.length]);

  useEffect(() => {
    if (shouldShowOnboardingModal) {
      showOnboardingModal();
    }
  }, [shouldShowOnboardingModal, showOnboardingModal]);

  return (
    <div className="flex h-full items-center justify-center">
      <Spinner />
    </div>
  );
};

Home.getLayout = getLayout;

export default Home;
